import React from 'react';
import { graphql } from 'gatsby';
import DefaultLayout from '../layouts/Default';
import NewsArticlePage from '../components/NewsArticlePage';

const PressRelease = ({ data }) => {
	const { articleCopy, download, id, articleImage, title, slug, primarySite, subtitle } =
		data.multisitePressReleases;
	const { nodes: pressReleases } = data.allMultisitePressReleases;
	const otherPressReleases = pressReleases.filter((article) => article.id !== id);
	const latestArticles = otherPressReleases
		.sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate))
		.slice(0, 3);

	return (
		<NewsArticlePage
			articleImageUrl={articleImage?.url}
			title={title}
			slug={slug}
			primarySite={primarySite}
			subHeading={subtitle}
			latestArticles={latestArticles}
			downloadUrl={download?.url}
			articleCopy={articleCopy}
			pageType="Press Releases"
		/>
	);
};

const WrappedPressReleasePage = (props) => (
	<DefaultLayout fullWidth>
		<PressRelease {...props} />
	</DefaultLayout>
);

export default WrappedPressReleasePage;

export const query = graphql`
	query pressQuery($id: String) {
		multisitePressReleases(slug: { ne: null }, id: { eq: $id }) {
			title
			subtitle
			id
			articleImage {
				url
			}
			articleCopy
			download {
				url
			}
			newsType
			slug
			primarySite
		}
		allMultisitePressReleases {
			nodes {
				title
				subtitle
				isArticleInternal
				externalArticleLink
				releaseDate
				articleImage {
					url
				}
				id
				slug
				newsType
			}
		}
	}
`;
